import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import withIsInApp from '../../withIsInApp';
import withPageEntry from '../../withPageEntry';
import DataJson from '../../../data.json';
import {ArrowBack} from '@styled-icons/boxicons-regular/ArrowBack';

const Tabs = {
  fee: {
    label: '會費紀錄',
    columns: [
      {
        title: '年度',
        dataIndex: 'FEE_YEAR',
        key: 'FEE_YEAR',
        width: 100,
        fixed: 'left',
      },
      {
        title: '起',
        dataIndex: 'BMONTH',
        key: 'BMONTH',
      },
      {
        title: '訖',
        dataIndex: 'EMONTH',
        key: 'EMONTH',
      },
      {
        title: '繳費狀況',
        dataIndex: 'PAY_STATUS',
        key: 'PAY_STATUS',
        render: (_, record) => {
          function getLabel() {
            if (record.PAY_STATUS === 'N') {
              return record.REFUND !== 0 ? '退款' : '未繳';
            } else if (record.PAY_STATUS === 'S') {
              return '停繳';
            } else if (record.PAY_STATUS === 'Y') {
              return '已繳';
            } else if (record.PAY_STATUS === 'F') {
              return '免繳';
            } else {
              return '未繳';
            }
          }
          return <div style={{color: 'red'}}>{getLabel()}</div>;
        },
      },
      {
        title: '應收',
        dataIndex: 'RECEIVABLE',
        key: 'RECEIVABLE',
      },
    ],
  },
  courses: {
    label: '參與課程紀錄',
    columns: [
      {
        title: '課程編號',
        dataIndex: 'class_no',
        key: 'class_no',
        width: 50,
        fixed: 'left',
      },
      {
        title: '課程名稱',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '類別',
        key: 'type_code',
        render: (_, it) => {
          if (it._type === 'ecourse') {
            return '影音課程';
          }
          return it.codebase?.CODE_NAME || it.type_code;
        },
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (it.complete_time || '').slice(0, 10);
        },
      },
      {
        title: '是否出席',
        key: 'attend',
        render: (_, it) => {
          if (it._type === 'ecourse') {
            return it.temp_time ? '是' : '否';
          }
          return it.is_present === 'Y' ? '是' : '否';
        },
      },
      {
        title: '核給點數',
        dataIndex: 'get_clocks',
        key: 'get_clocks',
      },
      {
        title: '使用免費點數',
        key: 'use_free',
        render: (_, it) => {
          return it.use_free === 'Y' ? '是' : '否';
        },
      },
      {
        title: '繳費狀況',
        dataIndex: 'pay_progress',
        key: 'pay_progress',
        render: (_, record) => {
          const valueMap = {
            30: '未繳',
            50: '少繳',
            90: '已繳',
            100: '免費',
            105: '自選免費',
            120: '作廢',
            160: '授權失敗',
            165: '逾期取消',
            200: '退款取消',
            0: '',
          };

          return (
            <div style={{color: 'red'}}>
              {valueMap[record.pay_progress] || '---'}
            </div>
          );
        },
      },
    ],
  },
  activities: {
    label: '參與活動紀錄',
    columns: [
      {
        title: '活動編號',
        dataIndex: 'activity_id',
        key: 'activity_id',
        width: 50,
        fixed: 'left',
      },
      {
        title: '活動名稱',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: '類別',
        key: 'type_code',
        render: (_, it) => {
          return it.codebase?.CODE_NAME || '';
        },
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (
            <>
              <div>{(it.start_date || '').slice(0, 10)}</div>
              <div>{it.start_time || ''}</div>
            </>
          );
        },
      },
      {
        title: '繳費狀況',
        dataIndex: 'pay_progress',
        key: 'pay_progress',
        render: (_, record) => {
          const valueMap = {
            30: '未繳',
            50: '少繳',
            90: '已繳',
            100: '免費',
            105: '自選免費',
            120: '作廢',
            160: '授權失敗',
            165: '逾期取消',
            200: '退款取消',
            0: '',
          };

          return (
            <div style={{color: 'red'}}>
              {valueMap[record.pay_progress] || '---'}
            </div>
          );
        },
      },
    ],
  },
  meetings: {
    label: '參與會議紀錄',
    columns: [
      {
        title: '會議名稱',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (
            <>
              <div>{(it.start_date || '').slice(0, 10)}</div>
              <div>{it.start_time || ''}</div>
            </>
          );
        },
      },
      {
        title: '地點',
        key: 'location',
        dataIndex: 'location',
      },
    ],
  },
  teaches: {
    label: '授課紀錄',
    columns: [
      {
        title: '課程編號',
        dataIndex: 'class_no',
        key: 'class_no',
        width: 50,
        fixed: 'left',
      },
      {
        title: '課程名稱',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '類別',
        key: 'type_code',
        render: (_, it) => {
          return it.codebase?.CODE_NAME || it.type_code;
        },
      },
      {
        title: '時間',
        key: 'time',
        render: (_, it) => {
          return (it.session_time || '').slice(0, 10);
        },
      },
      {
        title: '核給點數',
        dataIndex: 'get_clocks',
        key: 'get_clocks',
      },
    ],
  },
};

function LegacyDataPage(props) {
  const {isLoadingAppToken, fromMobileApp} = props;
  const [user] = useOutlet('user');
  const [selectedTab, setSelectedTab] = React.useState('fee');
  const [records, setRecords] = React.useState([]);
  const [isLoadingFetchData, setIsLoadingFetchData] = React.useState(true);
  const tabInfo = Tabs[selectedTab];

  const fetchData = React.useCallback(async () => {
    AppActions.setLoading(true);
    setIsLoadingFetchData(true);
    setRecords([]);

    try {
      if (selectedTab === 'fee') {
        const resp = await JStorage.fetchAllDocuments(
          'Annual_Fee',
          {
            MBR_ID: user.data.ID,
            PAY_STATUS: {$nin: ['S', 'N']},
          },
          ['-FEE_YEAR', '-BMONTH'],
        );
        setRecords(resp);
      } else if (selectedTab === 'courses') {
        const courseResp = await JStorage.fetchAllDocuments('Course_History', {
          member_id: user?.data?.ID,
        });
        const ecourseResp = await JStorage.fetchAllDocuments(
          'ECourse_History',
          {
            member_id: user.data.ID,
          },
        );
        const codeResp = await JStorage.fetchAllDocuments('code_base', {});

        const results = [
          ...courseResp.map((c) => ({_type: 'course', ...c})),
          ...ecourseResp.map((c) => ({_type: 'ecourse', ...c})),
        ]
          .map((c) => {
            const extras = {};

            if (c._type === 'ecourse') {
              extras.complete_time =
                c.complete_time1 || c.complete_time2 || c.complete_time3;
            }

            extras.codebase = codeResp.find((it) => it.CODE_NO === c.type_code);

            return {
              ...c,
              ...extras,
            };
          })
          .sort((a, b) => {
            if (a.complete_time > b.complete_time) {
              return -1;
            } else if (a.complete_time < b.complete_time) {
              return 1;
            }
            return 0;
          });

        setRecords(results);
      } else if (selectedTab === 'activities') {
        const codeResp = await JStorage.fetchAllDocuments('code_base', {});
        const results = (
          await JStorage.fetchAllDocuments('Activity_History', {
            member_id: user.data.ID,
          })
        )
          .map((c) => {
            const extras = {};
            extras.codebase = codeResp.find((it) => it.CODE_NO === c.type_code);

            return {
              ...c,
              ...extras,
            };
          })
          .sort((a, b) => {
            if (a.start_date > b.start_date) {
              return -1;
            } else if (a.start_date < b.start_date) {
              return 1;
            }
            return 0;
          });
        setRecords(results);
      } else if (selectedTab === 'meetings') {
        const results = (
          await JStorage.fetchAllDocuments('Meeting_History', {
            member_id: user.data.ID,
          })
        ).sort((a, b) => {
          if (a.start_date > b.start_date) {
            return -1;
          } else if (a.start_date < b.start_date) {
            return 1;
          }
          return 0;
        });
        setRecords(results);
      } else if (selectedTab === 'teaches') {
        const codeResp = await JStorage.fetchAllDocuments('code_base', {});
        const results = (
          await JStorage.fetchAllDocuments('Lecture_History', {
            member_id: user?.data?.ID,
          })
        )
          .map((c) => {
            const extras = {};
            extras.codebase = codeResp.find((it) => it.CODE_NO === c.type_code);
            return {
              ...c,
              ...extras,
            };
          })
          .sort((a, b) => {
            if (a.session_time > b.session_time) {
              return -1;
            } else if (a.session_time < b.session_time) {
              return 1;
            }
            return 0;
          });
        setRecords(results);
      }
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
      setIsLoadingFetchData(false);
    }
  }, [user, selectedTab]);

  React.useEffect(() => {
    if (!isLoadingAppToken && user && user.data) {
      fetchData();
    }
  }, [fetchData, isLoadingAppToken, user]);

  if (isLoadingAppToken) {
    return (
      <Wrapper selectedTab={selectedTab} fromMobileApp={fromMobileApp}>
        <div className="container">
          <div>Loading...</div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper selectedTab={selectedTab} fromMobileApp={fromMobileApp}>
      <div className="container">
        {!fromMobileApp && (
          <>
            <Ant.Button
              icon={
                <ArrowBack
                  size={24}
                  style={{paddingBottom: 3, marginRight: 6}}
                />
              }
              onClick={() => AppActions.navigate('/member')}
              type="link"
              size="large">
              返回會員服務
            </Ant.Button>
            <h1>112年前資料查詢結果</h1>
            {selectedTab === 'fee' && (
              <h3>
                本頁面為 2023-12-22 前的紀錄， 欲繳納當年度及 112
                年度常年會費，請至
                <span
                  className="link"
                  onClick={() => AppActions.navigate('/profile/fees')}>
                  繳納會費
                </span>
                頁面。
              </h3>
            )}
            {selectedTab !== 'fee' && (
              <h3>
                本頁面為 2023-12-22 前的紀錄，後續紀錄請至
                <span
                  className="link"
                  onClick={() => AppActions.navigate('/joins')}>
                  參與紀錄
                </span>
                查看。
              </h3>
            )}
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            borderBottom: '1px solid #ccc',
          }}>
          {Object.keys(Tabs).map((tab) => {
            const it = Tabs[tab];
            return (
              <Ant.Button
                key={tab}
                type={selectedTab === tab ? 'primary' : 'link'}
                onClick={() => {
                  setSelectedTab(tab);
                }}>
                {it.label}
              </Ant.Button>
            );
          })}
        </div>

        <Ant.Table
          key={selectedTab}
          columns={tabInfo.columns}
          dataSource={records}
          locale={{emptyText: isLoadingFetchData ? 'Loading' : undefined}}
          pagination={{
            total: (records || []).length,
            showTotal: (total) => `共 ${total} 筆`,
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: ${(props) => (props.fromMobileApp ? '0px' : 'var(--topNavBarHeight)')}
    auto 0px;

  min-height: 100vh;
  padding: 12px;
  background-color: #eee;

  & > .container {
    margin: 40px auto;
    max-width: var(--containerMaxWidth900px);
    padding: 20px 40px;
    background-color: white;
    border-radius: 4px;

    @media screen and (max-width: 768px) {
      margin: 20px auto;
      padding: 12px;
    }

    & > h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    & > h3 {
      margin-bottom: 25px;
      text-align: center;
      white-space: pre-wrap;
      color: var(--secondColor);

      & > .link {
        margin-left: 5px;
        margin-right: 5px;
        padding-bottom: 3px;
        border-bottom: 1px solid var(--secondColor);
        cursor: pointer;
      }
    }
  }
`;

export default withIsInApp(withPageEntry(LegacyDataPage));
